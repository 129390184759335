import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {AppService} from "../../../shared/services/app.service";
import {SiteService} from "../../../shared/services/site.service";
import {H} from "../../../shared/helpers/H";
import {globalEconGroupedBarsLayout} from "./plots";
import {Action, ActionEconomyPerAgent} from "../../../shared/models/Action.model";
import {K} from "../../../shared/models/K";
import {PlotlyService} from "angular-plotly.js";
import {SiteTariffConfig, SiteTariffData} from "../../../shared/models/models";
import {Plotly} from "angular-plotly.js/lib/plotly.interface";
import {boolean} from "mathjs";


@Component({
    selector: 'monitor-econ',
    template: `
        <div fxLayout="column" class="full-width h100 h-100">
            <div fxLayout="row" class="h-full">
                <div fxLayout="column" fxFlex="300px">
                    <mat-card class="p-4 m-4">
                        <h3>Selection des bâtiments </h3>
                        <div>
                            <button (click)="selectAllBat()">Select all</button>
                            <button (click)="deSelectAllBat()">DeSelect all</button>
                        </div>
                        <div class="scroller">
                            <div *ngFor="let batKV of batimentsMap|keyvalue" (click)="toggleBatiment(batKV.key)">
                                <mat-checkbox [checked]="batimentsToWatchMap.get(batKV.key)">

                                </mat-checkbox>
                                {{ batKV.key }} {{ batKV.value }}
                            </div>
                        </div>
                    </mat-card>
                </div>
                <div fxLayout="row wrap" fxFlex="50" fxLayoutAlign="start start">

                    <div fxFlex="66" class="p-0 m-0">
                        <mat-card class="p-4 m-4">
                            <div id="plot-carbon"></div>
                        </mat-card>
                    </div>

                    <div *ngFor="let ag of agents" fxFlex="33" class="p-0 m-0">
                        <mat-card class="p-4 m-4">
                            <div [id]="'plot-'+ag" id="plot-{ag}"></div>
                        </mat-card>
                    </div>

                </div>
                <div fxLayout="column" fxFlex fxLayoutAlign="space-between none">
                    <mat-card class="p-0 m-4">
                        <div fxLayout="row" class="p-8">
                            <button mat-raised-button class="btn-xs-25 mr-4"
                                    [class.active]="selectedYear===y"
                                    *ngFor="let y of availableYears" (click)="filterEconomiesPerYear(y)">
                                {{ y }}
                            </button>
                        </div>
                        <div fxLayout="row" class="p-8">
                            <button mat-raised-button class="btn-xs-25 mr-4"
                                    [class.active]="selectedAgent===ag"
                                    *ngFor="let ag of agents" (click)="filterEconomiesPerAgent(ag)">
                                {{ myapp.k.EnergyAgentsDisplayNames[ag] }}
                            </button>
                        </div>
                        <div class="all-economies-table-holder">

                            <!-- Header Table -->
                            <table class="all-economies-table header-table">
                                <tr class="head">
                                    <td class="td1">i</td>
                                    <td class="title td2">Batiment</td>
                                    <td class="td3">APE: Num (année)</td>
                                    <td class="td4">Fluide | Agent</td>
                                    <td class="td5">Libellé</td>
                                    <td class="td6">Tariff</td>
                                    <td class="td7">Economie Energie</td>
                                    <td class="td8">Economie Frs</td>
                                    <td class="td9">Co2</td>
                                </tr>
                            </table>

                            <!-- Data Table -->
                            <div class="table-data-container">
                                <table class="all-economies-table data-table">
                                    <tr *ngFor="let econ of economiesToShow" (click)="myapp.showConsole(econ)">
                                        <td class="td1">{{ econ.index }}</td>
                                        <td class="title td2">{{ econ.ref }}</td>
                                        <td class="td3">APE: {{ econ.ape }} ({{ econ.year }})</td>
                                        <td class="td4">{{ econ.fluid }} | {{ econ.agent }}</td>
                                        <td class="td5">{{ econ.label }}</td>
                                        <td class="td6">{{ econ.price }}</td>
                                        <td class="td7">{{ econ.amount.value|number }} {{ econ.amount.unit }}</td>
                                        <td class="td8">{{ econ.econ_chf|number }}</td>
                                        <td class="td9">{{ econ.emitted|number }}</td>
                                    </tr>
                                </table>
                            </div>
                            <div class="totals-summary">
                                <div class="totals-row">
                                    <div class="totals-label">
                                        <strong>Total:</strong>
                                    </div>
                                    <div class="totals-values">
                                        <div class="total-item">
                                            <strong>{{ calculateTotalEnergySavings() | number }}</strong>
                                        </div>
                                        <div class="total-item">
                                            <strong>{{ calculateTotalFinancialSavings() | number }} CHF</strong>
                                        </div>
                                        <div class="total-item">
                                            <strong>{{ calculateTotalCO2() | number }}</strong>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </mat-card>
                </div>
            </div>
        </div>
    `,
    styleUrls: ['./manager.component.scss']
})
export class MonitorEconComponent implements OnInit, OnDestroy {
    protected readonly globalEconGroupedBarsLayout = globalEconGroupedBarsLayout;
    allActions: Action[] = [];
    allEconomies: any[] = [];
    years: number[] = [];
    statsMap: Map<string, number> = new Map<string, number>();
    emitionMap: Map<string, number> = new Map<string, number>();
    batimentsMap: Map<string, number> = new Map<string, number>();
    batimentsToWatchMap: Map<string, boolean> = new Map<string, boolean>();

    agents: any [] = [];
    economiesToShow: any [] = [];
    baseBarTrace = {
        x: [],
        y: [],
        name: 'Chaleur',
        type: 'bar',
        barmode: 'group',
        yaxis: 'y',
        marker: {
            color: []
        }
    };
    availableYears = [];
    showCumul = true;
    selectedYear = 0;
    selectedAgent = "";
    unitAgentMap = {};

    get selectedBatiments() {
        const refList = [];
        this.batimentsToWatchMap.forEach((v, k) => {
            if (v) refList.push(k);
        });
        return refList;
    }

    constructor(
        public myapp: AppService,
        public site: SiteService,
        public plotlyServ: PlotlyService
    ) {

    }

    ngOnInit(): void {
        if (this.myapp.user) {
            // this.preRun();
            this.run();
        } else {
            this.myapp.appInitStatus.subscribe(step => {
                if (step === 3) {
                    this.run();
                }
            });
        }
    }

    preRun() {
        if (this.myapp.user && this.myapp.user.isAdmin) {
            this.run();
        } else {
            this.myapp.showError("Accès reservé aux administrateurs");
            setTimeout(() => {
                window.history.back();
            }, 4000);
        }
    }

    run() {
        this.myapp.storeCurrentRoute();
        this.site.api.getAllActions(this.myapp.user.email).subscribe(resp => {
            if (Array.isArray(resp.body)) {
                this.allActions = resp.body.map(it => new Action(it));
                console.log("Actions recues", this.allActions.length);
            }
            this.scanActions();
        });
    }

    // populate allEconomies array
    scanActions() {
        let counter = 1;

        this.allEconomies = [];
        this.allActions.forEach(act => {
            act.economies.forEach(econ => {
                const econObj = new ActionEconomyPerAgent(econ);
                ;
                const tariffKeyForRefYear = act._tariff_data['year_ref'] + '-' + econ.uid_tariff;

                const econObjectWithTarif = {...econ};
                const tariffObj = new SiteTariffConfig(act._tariff_data['tariff_config'][econ.uid_tariff]);
                this.unitAgentMap[tariffObj.agent] = this.myapp.k.unitShort[tariffObj.unit];
                const agent = tariffObj.agent !== 'NOAGENT' ? tariffObj.agent : 'WATER';
                econ.agent = agent;
                this.agents.push(agent);

                let tariffDataObj: SiteTariffData = null;
                if (act._tariff_data['energy_usage'][tariffKeyForRefYear]) {
                    tariffDataObj = new SiteTariffData(act._tariff_data['energy_usage'][tariffKeyForRefYear]);
                }
                econObjectWithTarif['index'] = counter;
                econObjectWithTarif['ref'] = act._tariff_data['ref'];
                econObjectWithTarif['ape'] = act.num;
                econObjectWithTarif['year'] = act.yearTerminated;
                econObjectWithTarif['tariffObj'] = tariffObj;
                econObjectWithTarif['fluid'] = tariffObj.fluid;
                econObjectWithTarif['agent'] = tariffObj.agent;
                econObjectWithTarif['amount'] = econObj.renderAmount(econObj.econEstimated);


                if (tariffDataObj) {
                    econObjectWithTarif['econ'] = econObj.econEstimated || 0;
                    econObjectWithTarif['econ_chf'] = econObj.economyInChf(tariffObj, tariffDataObj);
                    econObjectWithTarif['price'] = tariffDataObj.priceAdapted;
                    econObjectWithTarif['emitted'] = tariffDataObj.getCegesEmittedForInput(econObj.econEstimated) || 0;
                } else {
                    // console.log("Pas de tariffObj", econObjectWithTarif['ref'], econObjectWithTarif['ape'], tariffObj);
                }
                if (econObjectWithTarif['econ'] > 0 && act.yearTerminated > 2016) {
                    this.availableYears.push(act.yearTerminated);
                    this.allEconomies.push(econObjectWithTarif);
                }
                counter++;
                this.batimentsToWatchMap.set(econObjectWithTarif['ref'], true);
                H.incrStatMap(this.batimentsMap, econObjectWithTarif['ref'], 1);
            });
        });

        this.availableYears = H.arrayUnique(this.availableYears).sort();
        this.agents = H.arrayUnique(this.agents).sort();


        this.getStats();

    }

    // populate stats maps
    getStats() {
        this.statsMap = new Map<string, number>();
        this.emitionMap = new Map<string, number>();


        this.allEconomies
            .filter(econ => this.selectedBatiments.includes(econ.ref))
            .forEach(economies => {
                const key = economies['year'] + "_" + economies['agent'];
                H.incrStatMap(this.statsMap, key, economies['econ']);
                H.incrStatMap(this.emitionMap, economies['year'].toString(), economies['emitted']);
            });
        console.log("Units", this.unitAgentMap);

        this.availableYears.forEach(y => {
            this.agents.forEach(ag => {
                const key = y + "_" + ag;
                H.incrStatMap(this.statsMap, key, 0);
                H.incrStatMap(this.emitionMap, y.toString(), 0);
            });
        });
        this.renderEnergyPlots();
        this.renderCarbonPlot();
    }

    renderEnergyPlots() {
        const tracesHolder = {};
        this.statsMap = new Map([...this.statsMap.entries()].sort());
        let prevY = {};
        this.statsMap.forEach((v, k) => {
            const agent = k.split(/_(.*)/s)[1];
            const year = Number(k.split(/_(.*)/s)[0]);
            if (!tracesHolder[agent]) {
                const trace = JSON.parse(JSON.stringify(this.baseBarTrace));
                trace.name = agent;
                tracesHolder[agent] = trace;
            }
            let correctedValue = v;
            if (agent !== "WATER" && agent !== "EAU") {
                correctedValue = correctedValue / 1e6;
            }
            tracesHolder[agent]['name'] = agent;
            tracesHolder[agent].x.push(year);

            if (!prevY[agent]) prevY[agent] = 0;
            prevY[agent] += correctedValue;

            console.log(agent, year, correctedValue, prevY,)
            if (year === this.selectedYear)
                tracesHolder[agent].marker.color.push('#ff7f0e');
            else
                tracesHolder[agent].marker.color.push('#6666FF');
            tracesHolder[agent].y.push(prevY[agent]);

        });

        console.table(tracesHolder['WOOD'].x);
        console.table(tracesHolder['WOOD'].y);


        setTimeout(() => {
            this.agents.forEach((ag, index) => {
                const agentTraces = Object.values(tracesHolder).filter(trace => trace['name'] === ag);
                const agentLayout = {...this.globalEconGroupedBarsLayout};
                agentLayout.yaxis.title = this.unitAgentMap[ag];
                agentLayout.title.text = "Economies de " + this.myapp.k.EnergyAgentsDisplayNames[ag];
                // @ts-ignore
                Plotly.newPlot(`plot-${ag}`, agentTraces, agentLayout, {
                    ...this.myapp.k.defaultConfig,
                    responsive: false,
                    displayModeBar: false,
                }).then(gd => {
                    gd.on('plotly_click', (data) => {
                        console.log('Clicked:', {agent: ag, year: data.points[0].x});
                        this.filterEconomiesPerAgentAndYear(data.points[0].x, ag);
                        this.getStats();
                    });
                });
            });

        }, 100);
    }

    renderCarbonPlot() {
        const tracesHolder = JSON.parse(JSON.stringify(this.baseBarTrace));
        this.emitionMap = new Map([...this.emitionMap.entries()].sort());
        let prevY = 0;
        this.emitionMap.forEach((v, k) => {
            const agent = k.split(/_(.*)/s)[1];
            const year = Number(k.split(/_(.*)/s)[0]);

            const correctedValue = v;
            tracesHolder['name'] = agent;
            tracesHolder.x.push(year);
            prevY += correctedValue;
            if (year === this.selectedYear)
                tracesHolder.marker.color.push('#ff7f0e');
            else
                tracesHolder.marker.color.push('#6666FF');
            tracesHolder.y.push(prevY);

        });
        const carbonLayout = {...this.globalEconGroupedBarsLayout};
        carbonLayout.width = 800;
        carbonLayout['yaxis'] = {...carbonLayout.yaxis, title: "tCO2"};
        carbonLayout['title'] = {x: 0.02, text: "Emission de carbone"};
        setTimeout(() => {
            // @ts-ignore
            Plotly.newPlot("plot-carbon", [tracesHolder], carbonLayout, this.myapp.k.defaultConfig);
        }, 5);
    }

    // filtering for right pane list
    filterEconomiesPerAgentAndYear(year: number, agent: string) {
        this.selectedAgent = agent;
        this.selectedYear = year;
        this.economiesToShow = this.allEconomies
            .filter(econ => this.selectedBatiments.includes(econ.ref))
            .filter(econ => econ.year === year && econ.agent === agent);
    }

    filterEconomiesPerYear(year: number) {
        console.log("filterEconomies", year);
        this.selectedYear = year;
        this.filterEconomiesPerAgentAndYear(this.selectedYear, this.selectedAgent);
    }

    filterEconomiesPerAgent(agent: string) {
        this.selectedAgent = agent;
        console.log("filterEconomiesPerAgent", agent);
        this.filterEconomiesPerAgentAndYear(this.selectedYear, this.selectedAgent);
    }

    // calc total row
    calculateTotalEnergySavings(): number {
        return this.economiesToShow.reduce((total, econ) => {
            const value = econ.amount?.value || 0;
            return total + value;
        }, 0);
    }

    calculateTotalFinancialSavings(): number {
        return this.economiesToShow.reduce((total, econ) => {
            const value = econ.econ_chf || 0;
            return total + value;
        }, 0);
    }

    calculateTotalCO2(): number {
        return this.economiesToShow.reduce((total, econ) => {
            const value = econ.emitted || 0;
            return total + value;
        }, 0);
    }

    toggleBatiment(ref: string) {
        console.log("toggleBatiment", ref);
        const currValue = this.batimentsToWatchMap.get(ref);
        this.batimentsToWatchMap.set(ref, !currValue);
        this.getStats();
    }

    selectAllBat() {
        this.batimentsToWatchMap.forEach((v, k) => {
            this.batimentsToWatchMap.set(k, true);
        });
        this.getStats();
    }

    deSelectAllBat() {
        this.batimentsToWatchMap.forEach((v, k) => {
            this.batimentsToWatchMap.set(k, false);
        });
        this.getStats();
    }

    ngOnDestroy() {
    }

}