export const barDataItem = {
    x: [],// semaines
    y: [],// nb dexp
    name: 'SF Zoo',// salle
    type: 'bar',
    marker: {
        color: []
    }
};

export const perHourLayout = {
    height: 400,
    width: 700,
    xaxis: {
        showticklabels: true,
        labelalias: {},
        type: 'category',
        title: 'Jour de la semaine et tranche de 6h',
        autorange: true
    },
    yaxis: {
        title: 'Nb de tâches par type',
        fixedrange: true
    },
    showlegend: true,
    legend: {
        bgcolor: "#ffffff",
        bordercolor: "#000000",
        borderwidth: "1px",
        font: {family: "sans-serif", size: 11, color: "#000"},
        orientation: "h",
        y: 1,
        yanchor: "bottom"
    },
    title: {
        x: 0.02,
        text: 'Activités par jour et tranche de 6h'
    },
    font: {family: "Roboto, sans-serif", size: 12, color: "#000000"},
    margin: {
        b: 100,
        l: 65,
        pad: 5,
        r: 10,
        t: 60
    },
    barmode: 'stack',
};
export const graph = {
    data: [],
    layout: {
        margin: {
            b: 60,
            l: 60,
            pad: 10,
            r: 10,
            t: 70
        },
        font: {family: "Roboto, sans-serif", size: 12, color: "#000000"},
        legend: {
            bgcolor: "#ffffff",
            bordercolor: "#000000",
            borderwidth: "1px",
            font: {family: "sans-serif", size: 11, color: "#000"},
            orientation: "h",
            y: 1,
            yanchor: "bottom"
        },
        traceorder: 'alphabetical',
        barmode: 'stack',
        datarevision: 1,
        width: 800, height: 400,
        title: {
            x: 0.02,
            text: 'Nombre de publication par semaine et jour de semaine'
        },
        xaxis: {
            tickvals: [1, 3, 5, 7, 9, 11, 13, 15, 17, 19, 21, 23, 25, 27, 29, 31, 33, 35, 37, 39, 41, 43, 45, 47, 49, 51, 53],
            title: 'Numéro de semaine',
            fixedrange: true
        },
        yaxis: {
            title: 'Nb de publications',
            fixedrange: true
        },
    }
};
export const bmensBars = {
    data: [],
    layout: {
        margin: {
            b: 60,
            l: 60,
            pad: 10,
            r: 10,
            t: 70
        },
        font: {family: "Roboto, sans-serif", size: 12, color: "#000000"},
        legend: {
            bgcolor: "#ffffff",
            bordercolor: "#000000",
            borderwidth: "1px",
            font: {family: "sans-serif", size: 11, color: "#000"},
            orientation: "h",
            y: 1,
            yanchor: "bottom"
        },
        traceorder: 'alphabetical',
        barmode: 'stack',
        datarevision: 1,
        width: 600, height: 400,
        title: {
            x: 0.02,
            text: 'Nombre de publication par semaine et jour de semaine'
        },
        xaxis: {
            tickvals: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
            title: 'Mois',
            fixedrange: true
        },
        yaxis: {
            title: 'Nb de rapports partagés',
            fixedrange: true
        },
    }
};
export const globalEconGroupedBarsLayout = {
    margin: {
        b: 60,
        l: 60,
        pad: 10,
        r: 30,
        t: 30
    },
    font: {family: "Roboto, sans-serif", size: 12, color: "#000000"},
    legend: {
        bgcolor: "#ffffff",
        bordercolor: "#000000",
        borderwidth: "1px",
        font: {family: "sans-serif", size: 11, color: "#000"},
        orientation: "h",
        y: 1,
        yanchor: "bottom"
    },
    traceorder: 'alphabetical',
    barmode: 'group',
    datarevision: 1,
    width: 400, height: 330,
    title: {
        x: 0.02,
        text: undefined
    },
    xaxis: {
        title: 'Année',
    },
    yaxis: {
        anchor: 'x',
        title: 'GWh',
        side: 'left',
        autorange: true
    }
};

export const colorsMap = {
    "Melissa": "#8ea9db",
    "Frederic": "#c65911",
    "Yannick": "#ff0000",
    "Andrea": "#203764",
    "Nicolas": "#00b0f0",
    "Rija": "#000000",
    "Olaf": "#a9d08e",
    "Laurent": "#bf8f00",
    "Sylvie": "#ffe699",
    "Mathias": "#375623"
};

export const pieLayout = {
    width: 550, height: 250,
    margin: {
        b: 5,
        l: 0,
        pad: 0,
        r: 0,
        t: 10
    },
    title: {
        x: .50,
        text: ''
    },
};
export const pieData = [{
    values: [],
    labels: [],
    marker: {
        colors: []
    },
    type: 'pie'
}
];

export const progressData = [
    {
        domain: {x: [0, 1], y: [0, 1]},
        value: 200,
        type: "indicator",
        mode: "gauge+number+percent",
        gauge: {
            axis: {
                tickwidth: 1,
                tickcolor: "darkblue",
                range: [null, 110],
                tickvals: [10, 30, 50, 70, 90, 110, 120, 130, 140, 150, 160, 170],
            },
            bar: {'color': "darkblue"},
            bgcolor: "white",
            borderwidth: 2,
            bordercolor: "gray",
            steps: [
                {'range': [0, 33], 'color': '#fafafa'},
                {'range': [33, 66], 'color': '#eeeeee'},
                {'range': [66, 100], 'color': '#dddddd'}],
        },
    }
];
export const bmensProgressData = [
    {
        domain: {x: [0, 1], y: [0, 1]},
        value: 200,
        datarevision: 1,
        type: "indicator",
        mode: "gauge+number+percent",
        gauge: {
            axis: {
                tickwidth: 1,
                tickcolor: "darkblue",
                range: [null, 110],
                tickvals: [10, 30, 50, 70, 90, 110, 120, 130, 140, 150, 160, 170],
            },
            bar: {'color': "darkblue"},
            bgcolor: "white",
            borderwidth: 2,
            bordercolor: "gray",
            steps: [
                {'range': [0, 33], 'color': '#fafafa'},
                {'range': [33, 66], 'color': '#eeeeee'},
                {'range': [66, 100], 'color': '#dddddd'}],
        },
    }
];
export const progressGaugeLayout = {

    width: 250, height: 170, margin: {
        b: 15,
        l: 25,
        pad: 5,
        r: 40,
        t: 20
    },
    title: {
        x: 0,
        text: ''
    },
};
